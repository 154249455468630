import React, { useState } from "react";
import styled from "styled-components";
import VideoPopup from "../components/commonComponent/videoPopup";
import Layout from "../components/layout.component";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProjectListData } from "../hooks/project-list";
import { useParentHeader } from "../hooks/parent-review-header";
import SEO from "../components/seo";
const ProjectResult = () => {
    const [filter, setFilter] = useState("all");
    const programResult = useProjectListData();
    const aProgramResult = programResult.filter((item) => item.show_on_allpage === true);

    const caseStudyHeader = useParentHeader();
	const filterCaseStudyHeading = caseStudyHeader.filter((item) => item.page_type === "projects");
    const { heading, subheading } = filterCaseStudyHeading[0] ?? "";
	const [showVideo, setShowVideo] = useState(false);
	const [videoDetails, setVideoDetails] = useState({
		url: "",
		name: "",
	});
	const onClickPlayVideo = (url) => {
		setVideoDetails({
			url: url,
		});
		setShowVideo(true);
	};
    const onClickFilter = (name) => {
        setFilter(name);
	};
    return (
        <Layout>
            <SuccessStoriesStyled>
                <SuccessStoriesWrapperStyled>
                    <div dangerouslySetInnerHTML={{ __html: heading ?? "" }} />
                    <ResultFilterWrapper>
                        <label
                            htmlFor="all"
                            id="all_button"
                            name="all"
                            className={`${filter == "all" ? "isActive" : ""}`}
                            onClick={() => onClickFilter("all")}
                        >
                            All
                        </label>

                        <label
                            htmlFor="study_abroad"
                            id="study_abroad_button"
                            onClick={() => onClickFilter("Image-Gallery")}
                            className={`${filter == "Image-Gallery" ? "isActive" : ""}`}
							name="Image-Gallery"
                        >
                            Image Gallery
                        </label>

                        <label
                            htmlFor="permanent_resident"
                            id="permanent_resident_button"
							name="Video-Gallery"
                            onClick={() => onClickFilter("Video-Gallery")}
							className={`${filter == "Video-Gallery" ? "isActive" : ""}`}
                        >
                            Video Gallery
                        </label>
                    </ResultFilterWrapper>
                    <SuccessStoriesPeopleWrapperStyled>
						{
							filter === "all" ? (
								aProgramResult.map((data, index) => {
									if (data.type ==="Video-Gallery") {
										return (
											<div
												className="carousel_card"
												aria-hidden="true"
												onClick={() =>
													data?.video[0]?.strapi_component === "video-link.video-link"
														? onClickPlayVideo(
															data?.video[0]?.video_link,
														)
														: onClickPlayVideo(
															data?.video[0]?.upload_video?.url,
														)
												}
											>
												<div className="carousel_image">
													<img src={data?.thumbnail?.url} alt="FeedBack" />
													<FontAwesomeIcon icon={faPlay} size="1x" className="fa-play" />
												</div>
													<p>{data?.heading ?? ""}</p>
											</div>
										)
									} else
									return (
										<div className="carousel_card" key={index}>

												<div className="carousel_image">
													<img src={data?.thumbnail?.url} alt={data?.student_img?.name} />
												</div>
												<p>{data.heading}</p>
											</div>

								)
							})
							) : filter === "Image-Gallery" ? (
									aProgramResult.filter((data)=>data.type === filter).map((data,index) => {
										return (
											<div className="carousel_card" key={index}>
													<div className="carousel_image">
														<img src={data?.thumbnail?.url} alt={data?.student_img?.name} />
													</div>
													<p>{data.heading}</p>
												</div>
								)
							})
								) : filter === "Video-Gallery" ? (
										aProgramResult.filter((data) => data.type === filter).map((data, index) => {
											return (
												<div
													className="carousel_card"
													aria-hidden="true"
													onClick={() =>
														data?.video[0]?.strapi_component === "video-link.video-link"
															? onClickPlayVideo(
																data?.video[0]?.video_link,
															)
															: onClickPlayVideo(
																data?.video[0]?.upload_video?.url,
															)
													}
												>
													<div className="carousel_image">
														<img src={data?.thumbnail?.url} alt="FeedBack" />
														<FontAwesomeIcon icon={faPlay} size="1x" className="fa-play" />
													</div>
													<p>{data?.heading}</p>
												</div>
								)
							})
							):("")
						}
                    </SuccessStoriesPeopleWrapperStyled>
				</SuccessStoriesWrapperStyled>
				{showVideo && videoDetails.url !== "" && (
					<VideoPopup setShowVideo={setShowVideo} link={videoDetails.url} name={videoDetails.name} />
				)}
            </SuccessStoriesStyled>
        </Layout>
    );
};

const ResultFilterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	#all_button {
		padding: 0.625rem 1.5rem;
	}
	#study_abroad_button {
		padding: 0.625rem 1.25rem;
	}
	#permanent_resident_button {
		padding: 0.625rem 1.25rem;
	}
	@media (max-width: 500px) {
		padding: 0.8rem 0rem;
		justify-content: flex-start;
		width: 100%;
		overflow-y: scroll;
		gap: 0.5rem;
	}

	.isActive {
		background-color:  #2A264C;
		color: #fff0eb;
		transition: 0.2s all;
	}
	label {
		position: relative;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
		background: #fff0eb;
		border-radius: 63px;
		cursor: pointer;
	}
	#study_abroad_button {
		min-width: 9.25rem;
	}
	#permanent_resident_button {
		min-width: 12.1875rem;
	}
	label::before {
		content: "";
		height: 30px;
		width: 30px;
	}
	padding: 2rem 0.75rem;
`;
const SuccessStoriesPeopleWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3.5rem;
	width: 100%;
	margin-bottom: 3rem;
	flex-wrap: wrap;
	.carousel_card {
		background: #ffffff;
		box-shadow: 0px 4px 25px rgba(17, 58, 104, 0.15);
		border-radius: 16px;
		padding: 0.5em;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0.75em;
		width:300px;
		height: auto;
		// max-width: 16.4375em;
		margin-right: 1.5em;
		position: relative !important;
	}
	.carousel_image {
		border-radius: 12px;
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}
	.carousel_image img {
		min-width: 100%;
		max-width: 100%;
		min-height: 10rem;
		max-height:10rem;
		object-fit: contain;
	}
	.fa-play {
		font-size: 3rem;
		color: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}
`;
const SuccessStoriesWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	margin-bottom: 5rem;
	padding: 0.75rem;
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2A264C;
	}
	& p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1rem;
		line-height: 30px;
		text-align: center;
		color: #2A264C;
		margin-bottom: 1rem;
	}
	@media (max-width: 541px) {
		& h2 {
			font-size: 2.7rem;
		}
	}
	@media (max-width: 500px) {
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		& h1 {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 900;
			font-size: 28px;
			line-height: 34px;
			text-align: center;
			color: #2A264C;

		}
		& p{
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: #2A264C;
		}
		#good_university_subheading {
			font-size: 1rem;
			line-height: 19px;
			font-weight: 500;
			margin-bottom: 3rem;
		}
	}

	@media (max-width: 355px) {
		& h2 {
			font-size: 1.5rem;
		}
	}

	@media (max-width: 315px) {
		& h2 {
			font-size: 1.3rem;
		}
		#good_university_subheading {
			font-size: 0.9rem;
		}
	}
`;

const SuccessStoriesStyled = styled.section`

	.good_university_people_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		color: var(--dark-blue);
	}
	@media (max-width: 390px) {
		.good_university_people_card_details {
			width: 100%;
		}
		.good_university_people_card_wrapper {
			min-width: 100%;
			max-width: 100%;
		}
		.good_university_people_card_img_wrapper {
			min-width: 100%;
			max-width: 100%;
		}
	}

	@media (max-width: 355px) {
		.good_university_people_card_wrapper {
			min-width: 18rem;
			max-width: 18rem;
		}
		.good_university_people_info {
			font-size: 0.8rem;
		}
		.good_university_people_read_more {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 315px) {
		.good_university_people_card_wrapper {
			min-width: 15rem;
			max-width: 15rem;
		}
		.good_university_people_read_more {
			margin-right: 0rem;
			font-size: 0.8rem;
		}
		.good_university_people_name {
			font-size: 0.8rem;
		}
		.good_university_people_desgn {
			font-size: 0.7rem;
		}
		.good_university_people_info {
			font-size: 0.7rem;
		}
	}
`;

export const Head = () => {
	return (
		<SEO title="The Real School | Learn with an Effective Personalized Virtual School in India"
			description="Ensure the success of the kids in their choice of fields with the best mentorships and expertise with the courses for kids and the online learning for children with virtual schools in India."
			keywords="online classes for kids india online activity classes for kids activity classes for kids"
		/>
	)
}

export default ProjectResult;
