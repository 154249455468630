import { useStaticQuery, graphql } from "gatsby";

export const useParentHeader = () => {
    const { allStrapiParentReviewHeading } = useStaticQuery(
        graphql`
        query parentheaderquery{
           allStrapiParentReviewHeading{
             nodes{
                heading
                page_type
            }
           }
        }
        `
    )
    return allStrapiParentReviewHeading.nodes
}