import { useStaticQuery, graphql } from "gatsby";

export const useProjectListData = () => {
    const { allStrapiProjectList } = useStaticQuery(
        graphql`
			query programResult {
				allStrapiProjectList {
					nodes {
						thumbnail {
							url
						}
						heading
                        type
						slug
                        show_on_allpage
						video {
							... on STRAPI__COMPONENT_UPLOAD_VIDEO_UPLOAD_VIDEO {
								id
								strapi_id
								strapi_component
								upload_video {
									url
									name
								}
							}
							... on STRAPI__COMPONENT_VIDEO_LINK_VIDEO_LINK {
								id
								video_link
								video_name
								strapi_component
							}
						}
					}
				}
			}
		`
    );
    return allStrapiProjectList.nodes;
};
